import React from 'react';
import './why-section.scss'
function WhySection() {
    return (
        <section className={"why"}>
            <div className={"container why-container"}>
                Why Section
                <div className={"row why-row"}>

                </div>
            </div>
        </section>
    );
}

export default WhySection;
